<template>
  <el-form ref="form" :model="form" label-width="120px">
    <el-form-item label="股票代码">
      <!-- <el-input
        v-model="form.code"
        placeholder="输入股票代码, 例如:  002069"
      ></el-input> -->
      <el-autocomplete
        v-model="form.code"
        :fetch-suggestions="stock_querySearch"
        class="inline-input"
        :trigger-on-focus="false"
        clearable
        placeholder="输入股票代码, 例如:  002069"
        @select="stock_handleSelect"
      />
    </el-form-item>

    <el-form-item label="名称">
      <el-input
        v-model="form.name"
        placeholder="输入股票名称, 例如: 獐子岛"
      ></el-input>
    </el-form-item>

    <el-form-item label="城市">
      <el-autocomplete
        v-model="form.city"
        :fetch-suggestions="querySearch"
        class="inline-input"
        :trigger-on-focus="false"
        clearable
        placeholder="输入股票所属地,城市名,例如: 国际庄"
        @select="handleSelect"
      />
    </el-form-item>

    <!-- <el-form-item label="城市">
      <el-input
        v-model="form.city"
        placeholder="输入股票所属地,城市名,例如: 国际庄"
      ></el-input>
    </el-form-item> -->

    <el-form-item label="人口(万)">
      <el-input
        v-model="form.population"
        type="number"
        min="0"
        max="5000"
        placeholder="输入股票所属地,城市人口(万),例如: 1123"
      ></el-input>
    </el-form-item>

    <el-form-item v-if="canSee" label="操作人">
      <el-input
        v-model="form.chairman"
        placeholder="你不可以添加,别挣扎了"
      ></el-input>
    </el-form-item>

    <el-form-item v-else label="操作人">
      <el-input
        v-model="form.chairman"
        placeholder="你不可以添加,别挣扎了"
        show-password
      />
    </el-form-item>

    <el-form-item label="拉黑原因">
      <el-input
        v-model="form.description"
        type="textarea"
        placeholder="日常拉黑"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="create_stock">提交</el-button>
      <router-link to="stock">
        <el-button>取消</el-button>
      </router-link>
    </el-form-item>
  </el-form>
</template>

<script>
import { ElMessage } from "element-plus";
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      form: {
        name: "",
        code: "",
        description: "",
        city: "",
        population: "",
        chairman: "",
      },
      canSee: false,
      stocks: null,
      loading: true,
      errored: false,
    };
  },
  computed: {
    ...mapGetters({
      population: "population",
      stock: "stock",
    }),
  },
  methods: {
    create_stock() {
      const payloads = this.form;
      axios
        .post("/api/stocks", payloads)
        .then((response) => {
          this.stocks = response.data;
          console.log("返回:", this.stocks);
          ElMessage({
            message: "添加城市数据,成功!",
            type: "success",
          });
          this.$router.push({ name: "stock" });
        })
        .catch((error) => {
          console.log("data:", error.response.data);
          ElMessage.error(JSON.stringify(error.response.data.detail));
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    changeType() {
      this.canSee = !this.canSee;
    },
    stock_handleSelect(item) {
      console.log("select:", item);
      this.form.name = item.name;
      this.form.code = item.code.slice(0, -3);
    },
    stock_querySearch(queryString, cb) {
      const results = queryString
        ? this.stock.filter(this.createFilter(queryString))
        : this.stock;
      console.log("result:", results);
      cb(results);
    },
    handleSelect(item) {
      console.log("select:", item);
      this.form.population = item.population;
    },
    querySearch(queryString, cb) {
      const results = queryString
        ? this.population.filter(this.createFilter(queryString))
        : this.population;
      console.log("result:", results);
      cb(results);
    },
    createFilter(queryString) {
      return (item) => {
        return (
          item.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
  },
  mounted() {
    this.$store.dispatch("getPopulation");
    this.$store.dispatch("getStock");
  },
};
</script>

